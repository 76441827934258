<template>
  <button type="submit" class="bg-custom-green focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-custom-green  flex items-center hover:bg-custom-green-dark hover:shadow-md justify-center px-10 py-2.5 shadow text-white uppercase" :disabled="isLoading">
      <svg class="w-5 h-5 mr-3 -ml-1 text-white animate-spin" xmlns="http://www.w3.org/2000/svg" v-if="isLoading" fill="none" viewBox="0 0 24 24">
          <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
          <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>   <slot></slot>
  </button>
</template>
<script>
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
export default {
  props: {
    isLoading: {
      default: false
    }
  },
  setup () {
    return { faSpinner }
  }
}
</script>
